import React from 'react';
import { Container } from 'react-bootstrap';

const FourOhFor = () => (
  <Container>
    <div style={{ marginTop: '100px' }}>
      Four Oh Four
    </div>
  </Container>
);

export default FourOhFor;